<template>
	<div id="pastServices" >
    <div v-if='pastServicesList.length > 0'>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="pastServicesOne"  @click="toPastServicesDetail(v.id)" v-for="(v,i) in pastServicesList" :key='i'>
        <div class="pastServicesLine">
          <div class="pastServicesText">姓名：</div>
          <div class="pastServicesMassage">{{v.name}}</div>
        </div>
        <div class="pastServicesLine">
          <div class="pastServicesText">电话：</div>
          <div class="pastServicesMassage">{{v.phone}}</div>
        </div>
        <div class="pastServicesLine">
          <div class="pastServicesText">车务需求：</div>
          <div class="pastServicesMassage">{{v.trainDemandType}}</div>
        </div>
        <div class="pastServicesLine">
          <div class="pastServicesText">申请时间：</div>
          <div class="pastServicesMassage">{{v.applyTime}}</div>
        </div>
        <div class="moreDetail">
          <div class="moreText">更多</div>
          <div class="to_right"></div>
        </div>
      </div>
      </van-list>
    </div>
    <div class="emptyBox" v-else>
      <img src='../../assets/img/pastServicesEmpty.png' class="emptyImg"/>
    </div>
	</div>
</template>

<script>
import { validApp, getUrlParam, setCookie, getCookie, delCookie } from '../../lib/utils';
import { Dialog, Toast,List  } from 'vant';
import { trafficPastServicesByPage } from '@/server/request'
export default {
	data() {
		return {
      pastServicesList:[],
      limitPage: 3,
      currentPage: 1,
      finished: false,
      loading: false
		}
	},
	created() {
    document.title = '过往服务'
    this.gettrafficPastServices()
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    gettrafficPastServices(){
      let params = {
        data:{
          limit: this.limitPage,
          page: this.currentPage
        }
      }
      trafficPastServicesByPage(params).then(res=>{
        if(res.status=='200'){
          this.loading = false
          this.currentPage++
          if(res.data.list.length == 0){
            this.finished = true
          }else{
            this.pastServicesList.push(...res.data.list)
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    toPastServicesDetail(id){
      this.$router.push({path: '/pastServicesDetail', query: {id: id}})
    },
    onLoad(){
      setTimeout(() => {
        this.gettrafficPastServices()
        this.loading = true
      }, 500)
    }
  },
};
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
  background-color: #F5F5F5;
}
#pastServices{
  padding: 0.3rem 0.4rem 0;
}
.pastServicesOne{
  padding: 0.1rem 0.4rem 0.3rem;
  background-color: #ffffff;
  border-radius: 0.3rem;
  margin-bottom: 0.3rem;
}
.pastServicesLine{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 0.36rem;
  padding: 0.15rem 0;
}
.pastServicesText{
  width: 2rem;
  color: #333333;
  font-size: 0.3rem;
}
.pastServicesMassage{
  color: #666666;
  font-size: 0.3rem;
  width: 4.04rem;
}
.moreDetail{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.to_right {
	position: relative;
	margin-left: 0.1rem;
}
.to_right:after,
.to_right:before {
	border: solid transparent;
	content: ' ';
	height: 0;
	left: 100%;
	position: absolute;
	width: 0;
}
.to_right:after {
	border-width: 5px;
	border-left-color: #ffffff;
	top: -5px;
}
.to_right:before {
	border-width: 7px;
	border-left-color: #999999;
	top: -7px;
}
.moreText{
  font-size: 0.3rem;
  color: #999999;
}
.emptyBox{
  display: flex;
  justify-content: center;
}
.emptyImg{
  width: 2.6rem;
  height: 2.6rem;
  margin-top: 2rem;
}
</style>
