import axios from 'axios'
import store from '@/store'
import moment from 'moment';
import {getCookie} from './../lib/utils'

let server = axios.create({
    responseType: 'json'
});
server.interceptors.request.use(function (config) {
  let token = getCookie('CWTOKEN')
    // || 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvZnVscjFmV2lmM3BlTE1WWm10dnFPYndsMGZzIiwiYXVkIjoib2Z1bHIxZldpZjNwZUxNVlptdHZxT2J3bDBmcyIsImh6SWQiOm51bGwsImV4cCI6ODI4MTU5MTg3MCwiaWF0IjoxNjE0OTI1MjA0LCJjaGFubmVsSWQiOm51bGwsInVzZXJuYW1lIjoib2Z1bHIxZldpZjNwZUxNVlptdHZxT2J3bDBmcyJ9.HuAFJ4DgMqvSHsG6k5JY1Am5BLLZbUJuUB3MSaWRVG8';
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    store.commit('updatedIsLoading', true)
    return config;
}, function (err) {
    return Promise.reject(err);
});

function fetch(url, param) {
    return new Promise((resolve, reject) => {
        server.post(url, param)
          .then(({ data }) => {
            
                store.commit('updatedIsLoading', false);
                resolve(data);
            }, err => {
                store.commit('alertModal', {msg:'网络错误'});
                reject(err)
            })
    })
}
export const upFile = (param) => {
  return fetch('/oraflfile/upload',param);
};
//车务获取token
export function wechatLogin(data){
  return fetch('/oraflapi/apis', {
    appId: 'auth',
    method: 'wechatLogin',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ... data
  });
};
//过期的token换取新的token
export function updateWechatToken(data){
  return fetch('/oraflapi/apis', {
    appId: 'auth',
    method: 'updateWechatToken',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ... data
  });
};
//委托车务提交
export const trafficChargeSubmit = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficChargeSubmit',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//车务需求列表
export const trafficTrainDemandList = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficTrainDemandList',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//服务介绍
export const trafficServiceDesc = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficServiceDesc',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//客服咨询
export const customerConsultation = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'customerConsultation',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//过往服务列表
export const trafficPastServicesByPage = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficPastServicesByPage',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//过往服务提交_新增
export const trafficPastServicesAdd = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficPastServicesAdd',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//过往服务详情
export const pastServicesDetail = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'pastServicesDetail',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};
//过往服务提交_更新
export const trafficPastServicesUpdate = (data) => {
  return fetch('/oraflapi/apis', {
    appId: 'carcharge',
    method: 'trafficPastServicesUpdate',
    deviceType: 'WX',
    timestamp: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    ...data
  });
};